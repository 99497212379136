<template>
  <div id="box">
    <Header> 安全中心 </Header>

    <div class="box">
      <div class="way microblog" @click="deal">
        <div>交易密码</div>
        <img src="../../../../static/image/right.png" alt="" />
      </div>
      <div class="way" @click="cipher">
        <div>登录密码</div>
        <img src="../../../../static/image/right.png" alt="" />
      </div>
      <!-- <div class="way Alipay">
        <div>登录账号</div>
        <img src="../../../../static/image/right.png" alt="" />
      </div> -->
    </div>

    <div class="autonym" @click="autonym">
      <div>实名认证</div>
      <img src="../../../../static/image/right.png" alt="" />
    </div>
    <div class="autonym" @click="isShowAccount = true">
      <div>我的所有账户</div>
      <img src="../../../../static/image/right.png" alt="" />
    </div>

    <div class="selectPayType" v-if="isShowAccount">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请在APP内查看所有账户</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="isShowAccount = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowAccount: false,
    };
  },
  methods: {
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
      this.isShowAccount = false;
    },
    // 实名认证
    autonym() {
      this.$router.push('/Autonym');
      // Toast("实名认证暂缓开通");
    },
    // 登录密码
    cipher() {
      let params = {
        token: localStorage.getItem('token'),
      };
      // anyUserinfo;
      this.$api.anyUserinfo(params).then((res) => {
        if (res.code == 0) {
          if (res.data.is_set_login_pwd == 1) {
            this.$router.push('/Ciphertwo');
          } else {
            this.$router.push('/Cipher');
          }
        }
      });
    },
    // 设置交易密码
    deal() {
      let params = {
        token: localStorage.getItem('token'),
      };
      // anyUserinfo;
      this.$api.anyUserinfo(params).then((res) => {
        if (res.code == 0) {
          if (res.data.is_pay_password == 1) {
            this.$router.push('/Dealtow');
          } else {
            this.$router.push('/Deal');
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#box {
  height: 100vh;
  background-color: #f3f4f8;
  font-size: 13px;
  text-align: center;

  .microblog {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .Alipay {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .autonym {
    display: flex;
    justify-content: space-between;
    width: 341px;
    background-color: #ffffff;
    height: 46px;
    align-items: center;
    border-bottom: 1px solid #f8f8f8;
    margin-left: 17px;
    margin-top: 10px;
    border-radius: 5px;
    img {
      width: 7px;
      height: 12px;
      margin-right: 10px;
    }
    div {
      margin-left: 17px;
    }
  }
  .way {
    display: flex;
    justify-content: space-between;
    width: 341px;
    background-color: #ffffff;
    height: 46px;
    align-items: center;
    border-bottom: 1px solid #f8f8f8;
    margin-left: 17px;
    img {
      width: 7px;
      height: 12px;
      margin-right: 10px;
    }
    div {
      margin-left: 17px;
    }
  }
}
.topHeader {
  margin-bottom: 20px;
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 160px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      font-size: 16px;
      color: #666666;
      margin: 35px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        width: 90px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
</style>
